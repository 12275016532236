@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --max-width: 1100px;
  --border-radius: 12px;
  --font-mono: ui-monospace, Menlo, Monaco, 'Cascadia Mono', 'Segoe UI Mono',
    'Roboto Mono', 'Oxygen Mono', 'Ubuntu Monospace', 'Source Code Pro',
    'Fira Mono', 'Droid Sans Mono', 'Courier New', monospace;

  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;

  --primary-glow: conic-gradient(from 180deg at 50% 50%,
      #16abff33 0deg,
      #0885ff33 55deg,
      #54d6ff33 120deg,
      #0071ff33 160deg,
      transparent 360deg);
  --secondary-glow: radial-gradient(rgba(255, 255, 255, 1),
      rgba(255, 255, 255, 0));

  --tile-start-rgb: 239, 245, 249;
  --tile-end-rgb: 228, 232, 233;
  --tile-border: conic-gradient(#00000080,
      #00000040,
      #00000030,
      #00000020,
      #00000010,
      #00000010,
      #00000080);

  --callout-rgb: 238, 240, 241;
  --callout-border-rgb: 172, 175, 176;
  --card-rgb: 180, 185, 188;
  --card-border-rgb: 131, 134, 135;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

a {
  color: inherit;
  text-decoration: none;
}

.scrollbar-hide::-webkit-scrollbar {
  display: none;
  /* Oculta a barra de rolagem no WebKit (Chrome, Safari, Edge) */
}

::-webkit-scrollbar {
  @apply w-2;
}

/* Track */
::-webkit-scrollbar-track {
  @apply bg-slate-200 dark:bg-slate-800;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-slate-300 dark:bg-slate-700 rounded-md border border-red-400;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  @apply bg-slate-400 dark:bg-slate-600;
}

.Toastify__toast-body {
  text-align: center;
}

.no-text-highlight {
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Safari */
  -moz-user-select: none;
  /* Old versions of Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                       supported by Chrome, Edge, Opera and Firefox */
}

.no-numeric-arrow-input::-webkit-inner-spin-button,
.no-numeric-arrow-input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/* @media screen and (min-height: 1268px) {
  .table-products {
    height: 40rem;
  }
} */

@media screen and (min-height: 1200px) {
  .table-products {
    height: 80vh;
  }
}

@media screen and (max-height: 1200px) {
  .table-products {
    height: 75vh;
  }
}

@media screen and (max-height: 900px) {
  .table-products {
    height: 72vh;
  }
}

@media screen and (max-height: 800px) {
  .table-products {
    height: 68vh;
  }
}

@media screen and (max-height: 700px) {
  .table-products {
    height: 65vh;
  }
}

@media screen and (max-height: 650px) {
  .table-products {
    height: 62vh;
  }
}

@media screen and (max-height: 600px) {
  .table-products {
    height: 55vh;
  }
}

@media screen and (max-height: 480px) {
  .table-products {
    height: 45vh;
  }
}

@media screen and (max-height: 370px) {
  .table-products {
    height: 35vh;
  }
}


/* Para telas menores que 480px */
@media screen and (max-width: 480px) {
  .table-products {
    height: 23rem;
  }

  .product-list th,
  .product-list td {
    white-space: nowrap;
    /* Evita que o texto seja quebrado em várias linhas */
  }
}



/**Responsividade da Tabela de Produtos em Lote**/

@media screen and (min-height: 1200px) {
  .table-batch {
    height: 84vh;
  }
}

@media screen and (max-height: 1200px) {
  .table-batch {
    height: 84vh;
  }
}

@media screen and (max-height: 900px) {
  .table-batch {
    height: 80vh;
  }
}

@media screen and (max-height: 800px) {
  .table-batch {
    height: 82vh;
  }
}

@media screen and (max-height: 700px) {
  .table-batch {
    height: 78vh;
  }
}

@media screen and (max-height: 650px) {
  .table-batch {
    height: 76vh;
  }
}

@media screen and (max-height: 600px) {
  .table-batch {
    height: 74vh;
  }
}

@media screen and (max-height: 480px) {
  .table-batch {
    height: 70vh;
  }
}

@media screen and (max-height: 370px) {
  .table-batch {
    height: 62vh;
  }
}


/* Para telas menores que 480px */
@media screen and (max-width: 480px) {
  .table-batch {
    height: 23rem;
  }

  .product-list th,
  .product-list td {
    white-space: nowrap;
    /* Evita que o texto seja quebrado em várias linhas */
  }
}