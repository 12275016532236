/* tw-custom.css */

.bg-app-primary {
    @apply bg-gradient-to-r from-orange-500 to-orange-400;
}

.dark .bg-app-primary {
    @apply from-gray-800 to-gray-900;
}

.bg-default-secondary {
    @apply bg-gradient-to-r from-white to-slate-100;
}

.dark .bg-default-secondary {
    @apply bg-gradient-to-r from-gray-700 to-gray-800 text-white;
}

.bg-primary-btn {
    @apply rounded-[20px] bg-orange-400 px-4 py-2 text-base font-medium text-white transition duration-200;
}

.bg-primary-btn:hover {
    @apply bg-orange-500;
}

.bg-primary-btn:active {
    @apply bg-orange-200;
}

.dark .bg-primary-btn {
    @apply bg-gradient-to-r from-gray-700 to-gray-800 border-b border-gray-700 text-white;
}

.dark .bg-primary-btn:hover {
    @apply from-gray-800 to-gray-900 border-b border-gray-800;
}

.dark .bg-primary-btn:active {
    @apply from-gray-500 to-gray-700 border-b border-gray-500;
}

/* .secondary-brand-btn {
    @apply bg-orange-200 hover:bg-orange-400 active:bg-orange-100 text-gray-700 font-bold py-2 px-4 rounded-lg active:scale-75 duration-500 cursor-pointer w-full
} */

.secondary-brand-btn {
    @apply transition-all bg-orange-50 duration-200 text-center text-gray-700 p-1 rounded-[5px] cursor-pointer w-full border-solid border-2 border-orange-100;
}

.secondary-brand-btn:hover {
    @apply bg-orange-200 text-center;
}

.secondary-brand-btn:active {
    @apply bg-orange-100;
}

.dark .secondary-brand-btn {
    @apply bg-slate-600 border-slate-500 text-white;
}

.dark .secondary-brand-btn:hover {
    @apply bg-slate-800;
}

.bg-secondary {
    @apply bg-white;
}

.dark .bg-secondary {
    @apply bg-slate-700;
}
